.view.anon-feedback {
  background: linear-gradient(180deg, #e1eaf7 0%, #ffffff 54.14%);
  min-height: unset;

  .hero {
    text-align: center;
    margin-bottom: 20px;
    padding-top: 120px;

    @media (min-width: $tablet-portrait) {
      margin-bottom: 50px;
      padding-top: 225px;
    }

    h1 {
      @media (min-width: $tablet-portrait) {
        font-size: 54px;
        line-height: 60px;
      }
    }

    p {
      margin: 40px auto 0;

      @media (min-width: $tablet-portrait) {
        max-width: 60%;
      }
    }
  }

  .get-started {
    text-align: center;
    padding: 0 0 60px;

    h4 {
      margin-bottom: 20px;

      @media (min-width: $tablet-portrait) {
        font-size: 2rem;
      }
    }

    .feedback-container {
      max-width: 450px;
      margin: 0 auto;

      .input-err-message {
        background: transparent;
      }
    }

    .unavailable-container {
      max-width: 70%;
      margin: 0 auto;
    }


    .form-error {
      margin-bottom: 10px;
      margin-top: -10px;
    }
  }
}
