.marketing-header {
  width: 100%;
  color: $black;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;

    @media (min-width: $desktop-lg) {
      max-width: 1360px;
    }

    @media (min-width: $desktop-xl) {
      max-width: 1600px;
    }

    .left,
    .header-actions {
      //width: 25%;
      @media (min-width: $tablet-portrait) {
        width: 25%;
      }
    }

    .brand {
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      margin-right: 10px;

      .logo {
        height: 60px;
      }

      a {
        color: inherit;
      }

      .desktop {
        display: none;
        @media (min-width: $tablet-portrait) {
          display: initial;
        }
      }
      .mobile {
        @media (min-width: $tablet-portrait) {
          display: none;
        }
      }
    }

    nav {
      width: 50%;
      text-align: center;
      display: none;
      @media (min-width: $tablet-portrait) {
        display: initial;
      }

      a {
        padding: 0 10px;
        margin: 0 15px;
        color: inherit;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .header-actions {
      text-align: right;

      .btn {
        min-width: 80px;

        @media (min-width: $tablet-portrait) {
          min-width: 110px;
        }
      }
    }
  }
}

.app-header {
  height: 100px;
  width: 100%;
  color: $black;
  font-weight: bold;
  //background: $amsh-blue;
  position: absolute;
  left: 0;
  top: 0;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 15px;

    @media (min-width: $desktop-lg) {
      max-width: 1360px;
    }

    @media (min-width: $desktop-xl) {
      max-width: 1600px;
    }

    .left,
    .header-actions {
      //width: 25%;
      @media (min-width: $tablet-portrait) {
        width: 25%;
      }
    }

    .brand {
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      margin-right: 10px;

      .logo {
        height: 60px;
      }

      a {
        color: inherit;
      }

      .desktop {
        display: none;
        @media (min-width: $tablet-portrait) {
          display: initial;
        }
      }
      .mobile {
        @media (min-width: $tablet-portrait) {
          display: none;
        }
      }
    }

    nav {
      width: 50%;
      text-align: center;
      display: none;
      @media (min-width: $tablet-portrait) {
        display: initial;
      }

      a {
        padding: 0 10px;
        margin: 0 15px;
        color: inherit;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .header-actions {
      text-align: right;

      .btn {
        min-width: 80px;

        @media (min-width: $tablet-portrait) {
          min-width: 110px;
        }
      }
    }
  }
}


.main-header {
  padding: 15px 20px;
  position: absolute;
  width: 100vw;
  z-index: 1000;

  @media (min-width: $tablet-portrait) {
    padding: 25px 40px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left {
    display: flex;
    align-items: center;

    .brand {
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      margin-right: 10px;

      .logo {
        height: 60px;
      }

      .desktop {
        display: none;
        @media (min-width: $tablet-portrait) {
          display: initial;
        }
      }

      .mobile {
        @media (min-width: $tablet-portrait) {
          display: none;
        }
      }
    }

    .btn-link {
      display: none;
      @media (min-width: $tablet-portrait) {
        display: initial;
      }
    }
  }

  .header-actions {
    a {
      max-width: 150px;
      max-height: 40px;
      line-height: 40px;
      float: right;
    }
  }

  .header-center {
    text-align: center;
    a {
      margin-right: 40px;
    }
  }
}
