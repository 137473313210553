.view.blog {
  background: linear-gradient(180deg, #e1eaf7 0%, #ffffff 54.14%);

  .hero {
    text-align: center;
    margin-bottom: 20px;
    padding-top: 120px;

    @media (min-width: $tablet-portrait) {
      margin-bottom: 50px;
      padding-top: 155px;
    }

    p {
      margin: 40px auto 0;

      @media (min-width: $tablet-portrait) {
        max-width: 85%;
      }
    }
  }

  .blog-posts {
    a {
      text-decoration: none;
    }

    .blog-post {
      @extend .amsh-card;
      padding: 0;
      overflow: hidden;
      margin: 0 0 20px;

      @media (min-width: $tablet-portrait) {
        //margin: 0;
      }

      img {
        width: 100%;
      }

      .content {
        background: linear-gradient(215deg, #16b767 0%, #08b0af 54.14%);
        clip-path: polygon(100% 0, 100% 38%, 100% 100%, 0 100%, 0 20%);
        padding: 70px 30px 10px;
        margin-top: -70px;
        color: $white;

        h4 {
          font-weight: bold;
        }

        p {
          color: rgba($white, 0.95)
        }

        > span {
          font-weight: bold;
        }

        .blog-footer {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
        }
      }
    }
  }
}


.view.blog-post {
  background: linear-gradient(180deg, #e1eaf7 0%, #ffffff 54.14%);

  .blog-content {
    padding-top: 120px;

    @media (min-width: $tablet-portrait) {
      padding-top: 150px;
    }

    .feature-image {
      max-width: 80%;
      margin: 30px auto;
      display: block;
    }

    .raw-content {
      h3 {
        font-size: 34px;
        line-height: 39px;
      }
    }
  }

  .cta-container {
    background: linear-gradient(
                    176.76deg,
                    $white 0%,
                    rgba(225, 234, 247, 0) 15.12%,
                    #e1eaf7 97.32%
    );
    text-align: center;
    padding: 40px 0;

    @media (min-width: $tablet-portrait) {
      padding: 70px 0;
    }
  }
}
