.view.survey {
  background: $amsh-top-gradient;
  padding-top: 180px;

  .form-container {
    max-width: 850px;
    margin: 0 auto;

    h3 {
      margin: 0 0 30px;
    }

    .buttons  {
      text-align: right;
    }

    .success-content {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: all .5s;

      .amsh-card {
        margin: 30px;
        text-align: center;

        h3 {
          margin: 0;
        }
      }
    }

    .main-body {
      transition: all .5s;
    }

    &.success  {
      .success-content {
        max-height: 200px;
        opacity: 1;
      }

      .main-body {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
      }
    }
  }
}
