html,
body {
  font-family: Roboto;
  position: relative;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

h1 {
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;

  @media (min-width: $tablet-portrait) {
    font-weight: 900;
    font-size: 64px;
    line-height: 75px;
  }
}

h2 {
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;

  @media (min-width: $tablet-portrait) {
    font-size: 55px;
    line-height: 60px;
  }
}

h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  @media (min-width: $tablet-portrait) {
    font-size: 48px;
    line-height: 56px;
  }
}

textarea {
  resize: none;
}

.view {
  min-height: 100vh;
}

///
.main-header {
  position: absolute;
}

.form-control {
  background: transparent;
  border: none;
  border-radius: 0;
  width: 100%;
  height: 45px;
  border-bottom: 1px solid $dark-grey;
  position: relative;
  z-index: 1;

  &:focus, &:active {
    outline: none;
    box-shadow: none;
    border-color: $dark-grey;
  }
}

.form-error {
  color: $error-red;
}

label {
  font-weight: bold;
}
