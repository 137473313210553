.view.auth {
  background: $amsh-top-gradient;
  padding-top: 180px;

  &.login {

  }

  &.add-details {

  }

  &.integrations {
    text-align: center;

    .auth-card {
      @media(min-width: $tablet-portrait) {
        padding: 45px 75px;
      }

      .integrations-list {
        margin-top:  20px;
      }
    }

    .home-card {
      margin-bottom: 0;
      height: unset;

      img {
        max-height: 100%;
      }

      .title {
        font-size: 20px;
        line-height: 25px;
      }

      .btn {
        min-width: unset;
        width: 100%;
      }
    }

    .email {
      img {
        height: 100%;
      }
    }
  }

  &.add-slack {
    text-align: center;

    .content {
      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  &.add-billing {
    text-align: center;

    .btn-primary {
      margin-top: 50px;
    }
  }

  .auth-card {
    h3 {
      font-size: 43px;
      line-height: 52px;
      margin-bottom: 25px;
    }

    .or {
      position: relative;
      margin: 40px 0;

      hr {
        border-color: $black;
      }

      span {
        position: absolute;
        top: -13px;
        left: calc(50% - 30px);
        padding: 0 20px;
        background: $white;
      }
    }

    form {
      .form-icon {
        position: absolute;
        top: 15px;
        left: 24px;
        z-index: 10;

        &.pw {top: 14px;}
        &.email {top: 18px;}
      }

      .form-control {
        border-radius: 0;
        border: 1px solid $amsh-blue;
        margin: 20px 0;
        height: 60px;
        line-height: 60px;
        padding-left: 80px;

        &.error {
          border-color: $error-red;
          box-shadow: none;
        }
      }

      .actions {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;

        .btn-primary {
          margin-left: auto;
        }
      }
    }

    .buttons {
      margin-top: 40px;
      text-align: right;
    }
  }
}
