.view.configure-form {
  background: $amsh-top-gradient;
  padding-top: 120px;

  @media(min-width: $tablet-portrait) {
    padding-top: 160px;
  }

  .title-bar {
    text-align: center;
    max-width: 850px;
    margin: 0 auto 60px;

    @media(min-width: $tablet-portrait) {
      margin: 0 auto 100px;
    }
  }

  .form-container {
    max-width: 850px;
    margin: 0 auto;

    h3 {
      margin: 0 0 30px;
    }

    .buttons {
      text-align: right;
    }

    .report-details {
      @extend .question;

      .date-wrapper {
        display: flex;
        //margin-bottom: 20px;

        .dates {
          flex: .5;
        }

        .times {
          flex: 0.75;
          align-items: center;
          justify-content: center;
          display: flex;
          padding: 0 15px;

          @media(min-width: $tablet-portrait) {
            flex: 0.25;
          }

          .ant-picker {
            border: none;
            border-bottom: 1px solid $dark-grey;
            border-radius: 0;
            padding: 0 .75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            height: $input-height;
            margin-top: -15px;

            &.ant-picker-focused {
              box-shadow: none;
              border-color: $dark-grey;
            }
          }
        }

        .timezone {
          flex: .5;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            right: 10px;
            top: 20%;
            background: url("https://client-upload.bytehogs.com/amsh/media/amsh-arrow-grey.svg");
            display: block;
            height: 24px;
            width: 21px;
            z-index: 1;
          }
        }
      }

      .DayPickerInput {
        display: block;


        .DayPickerInput-Overlay {
          z-index: 10;
        }
      }

      .DayPickerInput, .sent-container {
        position: relative;
        cursor: pointer;

        .form-control {
          cursor: pointer;
        }

        &::after {
          content: "";
          position: absolute;
          right: 10px;
          top: calc(50% - 9px);
          background: url("https://client-upload.bytehogs.com/amsh/media/amsh-arrow-grey.svg");
          display: block;
          height: 24px;
          width: 21px;
          z-index: 0;
        }
      }

      .form-control:disabled, .form-control[readonly] {
        background: transparent;
      }

      select {
        width: 100%;
        appearance: none;
        color: $grey;
        border: none;
        border-bottom: 1px solid $dark-grey;
        height: 45px;
        //line-height: 45px;
        position: relative;

        &:focus, &:active {
          outline: none;
        }

        option {
          font-family: $roboto-font;
          color: $black;
        }
      }

      .buttons {
        margin-top: 30px;
      }
    }

    .main-body {
      transition: all .5s;
    }
  }
}
