
.view.app {
  &.report-details {
    .app-intro {
      h1 {
        max-width: unset;
      }

      .sent-date {
        display: block;
        font-size: 36px;

      }
    }
  }
}
