.view.home {
  background: linear-gradient(180deg, #e1eaf7 0%, #ffffff 54.14%);

  .topsec {
    padding-top: 225px;

    h1 {
      margin-bottom: 75px;
    }

    .hero {
      text-align: center;
      margin-bottom: 80px;

      @media (min-width: $tablet-portrait) {
        margin-bottom: 180px;
      }

      span {
        display: block;
        margin-top: 10px;
      }
    }

    .thinking {
      width: 520px;
      height: 366px;
      left: 735px;
      top: 648px;
      margin-bottom: 30px;

      @media (min-width: $tablet-portrait) {
        margin-bottom: 150px;
      }
    }

    h2 {
      margin-bottom: 25px;
    }

    h3 {
      margin-bottom: 25px;
    }
  }
  .create {
    @media (min-width: $tablet-portrait) {
      max-width: 75%;
    }
  }

  .better-your-business {
    text-align: center;

    .better p,
    > p {
      @media (min-width: $tablet-portrait) {
        max-width: 60%;
        margin: 0 auto;
      }
    }

    .better {
      margin-bottom: 40px;
    }

    .btn-primary {
      @media (min-width: $tablet-portrait) {
        margin-top: 45px;
        margin-bottom: 95px;
      }
      margin-top: 25px;
      margin-bottom: 45px;
    }
  }

  .bottomsec {
    background-color: #1e40c8;
    color: white;
    text-align: center;
    @media (min-width: $tablet-portrait) {
      padding: 100px 0;
    }
    padding: 50px 0;

    h2 {
      margin-bottom: 35px;
    }

    p {
      @media (min-width: $tablet-portrait) {
        max-width: 60%;
        margin: 0 auto;
      }
      max-width: 100%;
    }

    .btn {
      @media (min-width: $tablet-portrait) {
        margin-top: 60px;
      }
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }

  .thirdsec {
    //background: linear-gradient(0deg, #e1eaf7 0%, #ffffff 54.14%);

    .container {
      position: relative;
    }

    .row {
      margin-bottom: 40px;
      align-items: center;

      @media (min-width: $tablet-portrait) {
        margin-bottom: 150px;
        align-items: center;
      }

      img {
        margin-bottom: 40px;
        padding-right: 40px;

        @media (min-width: $tablet-portrait) {
          margin: 0;
          padding-right: 0;
        }
      }

      &.img-left {
        img {
          @media (min-width: $tablet-portrait) {
            padding-right: 50px;
          }
        }
      }

      &.img-right {
        img {
          @media (min-width: $tablet-portrait) {
            padding-left: 50px;
          }
        }
      }
    }

    h2 {
      @media (min-width: $tablet-portrait) {
        //margin-top: 165px;
        margin-bottom: 40px;
        width: 100%;
      }
      margin-bottom: 20px;
    }

    .works {
      color: $white;
      position: relative;
      padding: 10% 0 2.25%;

      .hiw-bg {
        top: 0;
        position: absolute;
        max-width: unset;
        width: 250vw;

        @media (min-width: $tablet-portrait) {
          width: 100vw;
        }
      }

      h2 {
        margin: 0 0 25px;
      }
      p {
        @media (min-width: $tablet-portrait) {
          margin-bottom: 35px;
          width: 75%;
        }
        width: 100%;
        margin-bottom: 25px;
      }
      .btn {
        margin-bottom: 65px;
      }
    }

    .caption {
      @media (min-width: $tablet-portrait) {
        width: 85%;
      }
      width: 100%;
      //margin-left: 150px;
      //margin-right: 115px;
    }

    .journey {
      text-align: center;
      margin-top: -190px;
      padding-top: 190px;
      padding-bottom: 40px;
      background: linear-gradient(
        176.76deg,
        $white 0%,
        rgba(225, 234, 247, 0) 9.12%,
        #e1eaf7 97.32%
      );

      @media (min-width: $tablet-portrait) {
        margin-top: -150px;
        padding-bottom: 50px;
      }

      h2 {
        margin-left: 0;
      }

      .btn {
        @media (min-width: $tablet-portrait) {
          margin-top: 60px;
        }
        margin-bottom: 10px;
        margin-top: 30px;
      }

      span {
        display: block;

      }
    }
  }
}
