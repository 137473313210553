.amsh-card {
  padding: $card-padding;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  background: $card-background;
}

.home-card {
  @extend .amsh-card;
  margin-bottom: 60px;
  height: calc(100% - 60px);

  @media (min-width: $tablet-portrait) {
    //padding-bottom: 80px;
  }

  .image-container {
    height: 130px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    display: block;
    font-weight: 900;
    margin-bottom: 8px;
  }

  p.content {
    max-width: initial;
    margin: 0;
  }
}

.cta-container {
  background: linear-gradient(
                  176.76deg,
                  $white 0%,
                  rgba(225, 234, 247, 0) 15.12%,
                  #e1eaf7 97.32%
  );
  text-align: center;
  padding: 40px 0;

  @media (min-width: $tablet-portrait) {
    padding: 150px 0;
  }
}

.auth-card {
  @extend .amsh-card;
  padding: 30px;
  margin: 0 auto 180px;
  border-radius: 0;
  max-width: 675px;

  @media (min-width: $tablet-portrait) {
    padding: 45px 100px;
  }

  .title {
    font-size: 28px;
    padding-bottom: 15px;
    @media (min-width: $tablet-portrait) {
      font-size: 48px;
      line-height: 56px;
    }
  }

  .content {
    font-size: 18px;
    line-height: 21px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .bhg-input-wrapper .bhg-input-group .input-err-message {
    line-height: 60px;
    background: transparent;
  }
}

.metric-card {
  @extend .amsh-card;
  padding: 50px;
  border-radius: 18px;
  text-align: center;
  color: $white;
  margin-bottom: 25px;
  position: relative;

  .metric-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    display: block;
  }

  .fa {
    position: absolute;
    top: 45px;
    right: 50px;
    cursor: pointer;
  }

  .metric-value {
    font-weight: 900;
    font-size: 64px;
    line-height: 75px;
    margin-top: 20px;
    display: block;
  }
}


.question {
  @extend .amsh-card;
  margin: 20px 0;
  text-align: left;

  @media (min-width: $tablet-portrait) {
    padding: 40px 110px;
  }

  .question-number {
    display: block;
    margin-bottom: 30px;
    font-weight: bold;
  }

  .question-text  {
    margin-bottom: 25px;
  }

  .parameters {
    margin: 20px 0;

    span {
      display: block;
      margin-bottom: 10px;
      font-style: italic;
    }

    .parameter {
      padding: 5px 15px;
      border-radius: 10px;
      background: $dark-grey;
      color: $white;
      width: max-content;

      &.pm-1 {
        background: $amsh-green;
        color: $white;
      }

      &.pm-2 {
        background: $amsh-blue;
        color: $white;
      }
    }
  }
}

.amsh-scale {
  margin: 0 20px 20px;
}

.amsh-checkboxes {
  label {
    display: block;

    span {
      margin-left: 10px;
    }
  }
}

.amsh-radios {
  label {
    display: block;

    span {
      margin-left: 10px;
    }
  }
}

.reports-list {
  margin-bottom: 25px;

  a {
    text-decoration: none;
  }

  .report-item {
    margin-bottom: 20px;
  }
}

.report-item {
  @extend .amsh-card;
  //padding: 30px 60px 30px 110px;

  @media(min-width: $tablet-portrait) {
    padding: 30px 60px 30px 110px;
  }

  * {
    font-size: 18px;
    line-height: 24px;
  }

  .report-date {
    display: block;
    color: $black;
    margin-bottom: 10px;
  }

  .sent-to {
    display: block;
    color: $amsh-blue;
    margin-bottom: 10px;
  }

  .response-rate {
    color: $amsh-green;
    font-weight: 900;
  }


  .row > * {
    display: flex;
    flex-direction: column;
  }

  .arrow {
    margin: auto;
  }
}

.report-question-list {
  margin-bottom: 25px;

  .report-question {
    margin-bottom: 20px;
  }
}

.report-question {
  @extend .question;
  padding: 20px;
  height: 300px;

  @media (min-width: $tablet-portrait) {
    padding: 30px 60px 50px 110px;
    min-height: 250px;
    height: 500px;
  }

  .question-text {
    margin: 0;
  }

  .report-chart {
    margin: 0 0 -30px -120px;
    height: 100%;
  }
}

.title-with-hr {
  margin-top: 25px;

  span {
    font-weight: 700;
    font-size: 16px;
  }

  hr {
    margin: 20px 0;
  }
}

.options-list {
  .display-option {
    color: $grey;
    padding: 15px 0;
    border-bottom: 1px solid $dark-grey;
  }
}

.subscription-warning {
  background: rgba($error-red, 0.45);
  border-radius: 30px;
  padding: 40px;
  text-align: center;
  margin-bottom: 40px;
}

.employee-list {
  .employee-list-item {
    padding: 10px 0;
    border-bottom: 1px solid $light-grey;
  }
}

.no-cc-req {
  display: block;
  margin-top: 10px;
}
