.view.settings {
  background: $amsh-top-gradient;
  padding-top: 80px;

  @media(min-width: $tablet-portrait) {
    padding-top: 160px
  }

  .form-control {
    margin-bottom: 10px;
  }

  .buttons {
    text-align: right;
    margin-top: 20px;

    &.left {
      text-align: left;
    }
  }

  .subscription-settings {
    .buttons {
      margin-bottom: 20px;
    }

    .warning {
      color: $error-red;
    }
  }

  .cards-settings {
    margin-top: 40px;

    h5 {
      margin-bottom: 20px;
    }

    .rccs {
      margin-left: 0;
      margin-bottom: 20px;
    }
  }
}
