.modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  cursor: pointer;
}

.modal {
  position: absolute;
  background: $white;
  overflow: auto;
  border-radius: 6px;
  outline: none;
  padding: 20px;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: initial;

  @media (max-width: ($tablet-portrait  - 1px)) {
    max-width: 90% !important;
  }

  &.animated {
    transition: all 0.5s;
  }

  &.parameter {
    max-width: 480px;
    max-height: 325px;

    h3 {
      text-align: center;
      margin-bottom: 25px;
      //color: $amsh-blue;
    }

    .btn {
      margin: 20px auto 0;
      display: block;
    }
  }
}
