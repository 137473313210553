.view.app {
  background: $amsh-top-gradient;
  padding-top: 120px;

  @media(min-width: $tablet-portrait) {
    padding-top: 200px;
  }

  .app-intro {
    margin-bottom: 100px;

    h1 {
      max-width: 410px;
    }
  }

  &.dashboard {

  }
}
