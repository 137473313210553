.footer {
  background-color: black;
  color: white;
  @media (min-width: $tablet-portrait) {
    padding-bottom: 90px;
  }
  padding-bottom: 60px;

  h2 {
    @media (min-width: $tablet-portrait) {
      padding-top: 115px;
      padding-bottom: 30px;
    }
    padding-top: 50px;
    padding-bottom: 15px;
    text-align: left;
  }

  p {
    @media (min-width: $tablet-portrait) {
      margin-bottom: 75px;
    }
    margin-bottom: 40px;
  }

  input,
  textarea {
    padding: 5px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 0;
    border-bottom: solid 1px $white;
    @media (min-width: $tablet-portrait) {
      margin-right: 80px;
    }

    &:focus,
    &:active {
      box-shadow: none;
      outline: none;
    }
  }

  .wl-input-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  textarea {
    @media (min-width: $tablet-portrait) {
      margin-top: 25px;
      margin-bottom: 75px;
      width: calc(100% - 80px);
    }
    margin-top: 15px;
    margin-bottom: 40px;
  }

  .links {
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-portrait) {
      flex-direction: row;
    }

    .legal-link {
      color: $white;
      text-decoration: underline;
      margin: 0 0 10px;
      display: block;

      @media (min-width: $tablet-portrait) {
        margin: 0 15px 0 0;
      }
    }
  }



  .powered-by-bytehogs {
    max-width: 100px;
    display: block;
    margin: 20px auto -40px;
  }
}
