.view.employees {
  background: $amsh-top-gradient;
  padding-top: 80px;
  padding-bottom: 20px;

  @media(min-width: $tablet-portrait) {
    padding-top: 160px
  }

  .form-control {
    margin-bottom: 10px;
  }

  .btn {
    margin-top: 20px;
  }

  .new-employee-form {
    max-width: 75%;
  }
}
