.view.integrations {
  background: linear-gradient(180deg, #e1eaf7 0%, #ffffff 54.14%);

  .hero {
    text-align: center;
    margin-bottom: 20px;
    padding-top: 120px;

    @media (min-width: $tablet-portrait) {
      margin-bottom: 50px;
      padding-top: 225px;
    }

    p {
      margin: 40px auto 0;

      @media (min-width: $tablet-portrait) {
        max-width: 85%;
      }
    }
  }

  .integration-list {
    .email {
      img {
        height: 100%;
      }
    }

    .home-card {
      height: calc(100% - 20px);
      margin-bottom: 20px;

      .image-container {
        margin-bottom: 15px;

        img {
          max-height: 100%;
        }
      }

      .title {
        text-align: center;
        font-size: 24px;
      }

      .card-cta-container {
        text-align: center;

        .btn {
          margin: 20px auto 0;
        }

        .coming-soon {
          margin: 20px 0 0;
          height: 46px;
          line-height: 46px;
          font-weight: bold;
        }
      }
    }
  }
}
