.view.roadmap {
  background: linear-gradient(180deg, #e1eaf7 0%, #ffffff 54.14%);

  .hero {
    text-align: center;
    margin-bottom: 20px;
    padding-top: 120px;

    @media (min-width: $tablet-portrait) {
      margin-bottom: 50px;
      padding-top: 225px;
    }

    p {
      margin: 40px auto 0;

      @media (min-width: $tablet-portrait) {
        max-width: 60%;
      }
    }
  }

  .map {
    .container {
      > div {

        margin: 0 0 115px;
        background: linear-gradient(
        176.76deg,
        $white 0%,
        rgba(225, 234, 247, 0) 60.12%,
        #e1eaf7 100%
        );
        position: relative;

        @media (min-width: $tablet-portrait) {
          max-width: 60%;
        }

        &:nth-child(odd) {
          margin-left: auto;

          &:after {
            left: 0;
            top: 100%;
            transform: rotate(105deg);
          }
        }

        &:after {
          content: "";
          background: url("https://client-upload.bytehogs.com/amsh/media/amsh-dotted-line.svg");
          background-repeat: no-repeat;
          height: 100px;
          width: 100px;
          display: block;
          position: absolute;
          transform: rotate(75deg);
          z-index: 1;
          top: 110%;
          left: 60%;
        }

        &:last-of-type {
          margin-bottom: 0;
          &:after  {
            display: none;
          }
        }

        .title {}

        p {
          margin: 0;
        }
      }
    }
  }
}
