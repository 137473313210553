@import "../bootstrap/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;900&display=swap");
@import '~react-day-picker/lib/style.css';

@import '~react-credit-cards/lib/styles.scss';

@import "variables";

@import "global";
@import "components";
@import "header";
@import "footer";
@import "buttons";
@import "modals";
@import "ant-picker";

@import "_pages/home";
@import "_pages/integrations";
@import "_pages/roadmap";
@import "_pages/anon-feedback";
@import "_pages/auth";
@import "_pages/dashboard";
@import "_pages/survey";
@import "_pages/reports";
@import "_pages/configure-form";
@import "_pages/settings";
@import "_pages/employees";
@import "_pages/legal";
@import "_pages/blog";

/*@import "typography";
@import "forms";*/
/*


@

@import "_pages/auth";
*/
