.btn {
  border-radius: $button-border-radius;
  border: none;
  height: $button-height;
  line-height: $button-line-height;
  font-weight: $button-font-weight;
  padding: $button-padding;
  margin: $button-margin;
  min-width: $button-min-width;

  &:focus {
    outline: none;
  }

  &.btn-rounded {
    border-radius: 25px;
  }

  &.btn-primary {
    background: $brand-primary;
    color: $white;
    box-shadow: $card-box-shadow;

    &:not(:disabled):not(.disabled):active {
      background: darken($brand-primary, 20%);
      color: $white;
    }

    &:not(:disabled):not(.disabled):focus {
      box-shadow: $card-box-shadow;
    }
  }

  &.btn-outline-primary {
    background: $white;
    color: $brand-primary;
    border: 1px solid $brand-primary;
    box-shadow: $card-box-shadow;

    &:not(:disabled):not(.disabled):active {
      border-color: transparent;
      background: $brand-primary;
      color: $white;
    }

    &:not(:disabled):not(.disabled):focus {
      background: $brand-primary;
      color: $white;
      box-shadow: $card-box-shadow;
    }
  }

  &.btn-secondary {
    background: $brand-secondary;
    color: $brand-primary;
    box-shadow: $card-box-shadow;

    &:not(:disabled):not(.disabled):active {
      background: darken($brand-primary, 20%);
      color: $white;
    }

    &:not(:disabled):not(.disabled):focus {
      box-shadow: $card-box-shadow;
    }
  }

  &.btn-link {
    color: $brand-primary;

    &:not(:disabled):not(.disabled):focus {
      box-shadow: none;
    }
  }

  &.btn-inline {
    padding: 0;
    min-width: 0;
    margin: 0;
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
    display: initial;
  }
}

.google-button {
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  //display: flex;
  //align-items: center;
  border: none;
  background: $white;

  img {
    width: 100%;
    height: 100%;
  }

  svg {
    height: 18px;
    width: 18px;
  }

  span {
    margin: 0 24px;
    display: block;
  }
}
